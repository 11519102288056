<template>
<div class="game-mode-section">
    <div class="clamped">
        <h4 id="more" class="section-heading">{{ $t('GAME_MODES.NUM_GAME_MODES', {number: props.gameModes.length}) }}</h4>
        <section>
            <div
                v-for="mode in gameModes"
                :key="mode.key"
                class="padded mode-container"
                :class="props.windowWidth < 600 ? 'flex-column' : 'flex-row'"
            >
                <div class="mode-info">
                    <p class="name">
                        <NuxtImg :src="iconImgSrc(mode)" :alt="mode.name || ''" />
                        {{ mode.name }}
                    </p>
                    <div v-md="mode.description" class="description" />
                </div>
                <div class="mode-media">
                    <NuxtImg :src="imgSrc(mode)" :alt="mode.name || ''" />
                </div>
            </div>
        </section>
    </div>
</div>
</template>

<script setup lang="ts">
import type { JBGWeb } from '$types/JBGWeb'

const props = defineProps({
    windowWidth: {
        type: Number,
        required: true
    },
    gameModes: {
        type: Array as PropType<JBGWeb.GameMode[]>,
        required: true
    }
})

const iconImgSrc = (mode: JBGWeb.GameMode): string => {
    if (!mode?.icon?.data?.attributes?.url) {
        return '/images/characters-bg.jpg?format=auto'
    }
    return `${mode.icon.data.attributes.url}?format=auto`
}

const imgSrc = (mode: JBGWeb.GameMode): string => {
    if (!mode?.image?.data?.attributes?.url) {
        return '/images/characters-bg.jpg?format=auto'
    }
    return `${mode.image.data.attributes.url}?format=auto`
}
</script>

<style lang="scss" scoped>
@import "../styles/kit.scss";

.clamped {
    width: 90%
}

.mode-container {
    display: flex;
    width: 100%;
    gap: 50px;
    margin: 20px auto 80px;
}

.mode-info {
    flex-basis: 50%;
    align-content: center;
    text-align: right;

    .name {
        position: relative;
        font-size: 45px;
        font-weight: 900;
        margin-bottom: 0.5rem;

        img {
            height: 36px;
            margin: 0 5px;
        }
    }

    .description {
        font-size: 16px;
        line-height: 1.3;

        // center align icons in description
        > * > * {
            vertical-align: middle;
            display: inline;
            height: 32px;
            margin: 0 5px 3px;
        }
    }

}

.mode-media {
    flex-basis: 50%;

    img {
        width: 100%;
        border-radius: 25px;
        box-shadow: -4px -4px 64px 48px rgba(255, 255, 255, 0.04);
    }
}

.flex-row:nth-child(even) {
    flex-direction: row-reverse;

    .mode-info {
        text-align: left;
    }
}

.flex-column {
    flex-direction: column-reverse;
    gap: 0px;
    margin: 10px auto 40px;

    .mode-info {
        text-align: left;

        .name {
            font-size: 32px;

            img {
                height: 28px;
                margin: 0 3px;
            }
        }
    }

    .mode-media {
        img {
            width: calc(100% + 32px);
            margin: 0 -16px 10px;
            border-radius: 0px;
        }
    }
}

@include mq-medium {
    .mode-info {
        .mode-name {
            font-size: 38px;

            img {
                height: 28px;
            }
        }

        .mode-description{
            font-size: 14px;
        }
    }

    .mode-media img {
        width: 100%;
    }
}

@include mq-small {
    .mode-media img {
        min-height: 250px;
        aspect-ratio: 1/1;
        object-fit: cover;
    }
}

@include mq-xsmall {
    .clamped {
        width: 100%
    }
}
</style>
