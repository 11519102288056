<template>
<div v-if="game?.matureRating && ageStatus !== 'approved'" class="age-gate">
    <AgeGate :status="ageStatus" v-on:status-update="updateStatus" />
</div>
<div v-else class="game-details" :class="classes">
    <div class="hero-section">
        <section class="hero grid vertical-center halves" :style="heroStyles">
            <div class="clamped">
                <div v-show="game && game.matureRating" class="ratings-icon">
                    <RatingsIcon />
                </div>
                <div v-if="(windowWidth > 600) && packDetails" class="hero-info">
                    <!--eslint-disable-next-line vuejs-accessibility/heading-has-content-->
                    <h1 v-if="game?.wordmark?.data?.attributes?.url">
                        <NuxtImg
                            :src="`${game.wordmark.data.attributes.url}?format=auto`"
                            :alt="game?.title || ''" class="wordmark landscape"
                            v-on:load="setImageOrientationClass"
                        />
                    </h1>
                    <div class="tag-container flex-row">
                        <NuxtLink :to="typeLink">
                            <Tag
                                v-if="gameType"
                                :text="gameType.name"
                                :icon="gameType.icon"
                            />
                        </NuxtLink>
                        <Tag
                            v-if="playerRange"
                            :text="`${playerRange} ${$t('TAGS.PLAYERS')}`"
                            icon="fa fa-regular fa-user-group"
                        />
                        <Tag
                            v-if="duration"
                            :text="`${duration} ${$t('TAGS.MINUTES')}`"
                            icon="fa fa-regular fa-clock-eleven-thirty"
                        />
                    </div>

                    <p class="description paragraph">{{ game?.shortDescription }}</p>
                </div>
                <div v-else-if="isStandalone" class="shopping standalone">
                    <PackShoppingOverlay
                        :pack="game" :product="product" :show-platforms="true"
                        :fell-back-to-en="fellBackToEn"
                        :is-localized="isLocalized"
                    />
                </div>
            </div>
        </section>
        <div class="xs-spacer" />
    </div>

    <div v-if="(windowWidth < 600) && packDetails" class="hero-info mobile padded">
        <NuxtImg
            v-if="game?.wordmark?.data?.attributes?.url"
            :src="`${game.wordmark.data.attributes.url}?format=auto`"
            :alt="game?.wordmark?.data?.attributes.alternativeText || ''" class="wordmark mobile"
            v-on:load="setImageOrientationClass"
        />

        <div class="tag-container flex-row">
            <Tag
                v-if="gameType"
                :text="gameType.name"
                :icon="gameType.icon"
            />
            <Tag
                v-if="playerRange"
                :text="`${playerRange} ${$t('TAGS.PLAYERS')}`"
                icon="fa fa-regular fa-user-group"
            />
            <Tag
                v-if="duration"
                :text="`${duration} ${$t('TAGS.MINUTES')}`"
                icon="fa fa-regular fa-clock-eleven-thirty"
            />
        </div>
        <p class="description paragraph">{{ game?.shortDescription }}</p>
    </div>

    <div class="fold-wrapper">
        <div v-if="packDetails" class="pack-section">
            <div class="clamped">
                <h2
                    v-t="{ path: 'GAME_DETAIL.PART_OF', args: { packName: packDetails?.title ?? '' }}"
                    class="section-heading title-6"
                />
                <section v-if="packDetails" class="pack-info" :class="{mobile: windowWidth < 600}">
                    <div class="flex-layout">
                        <div class="shopping">
                            <div v-if="!packDetails.displayEmailSignup" class="overlay-container">
                                <PackShoppingOverlay
                                    v-if="product && packDetails" :pack="packDetails"
                                    :product="product" :clickable-wordmark="true" :show-platforms="true"
                                    :heading="false" :fell-back-to-en="fellBackToEn"
                                    :is-localized="isLocalized"
                                />
                            </div>
                        </div>

                        <div v-if="otherGamesInPack" class="other-titles-container">
                            <div>
                                <p class="paragraph-xl other-titles">
                                    {{
                                        $t('PACK_DETAIL.OTHER_GAMES', {
                                            gameTitle: game?.title.toUpperCase(),
                                            numGamesInPack: otherGamesInPack?.length
                                        })
                                    }}
                                </p>
                            </div>
                            <div class="game-tiles-container">
                                <p
                                    v-for="(g, idx) in otherGamesInPack"
                                    :key="`${g}-${idx}`"
                                >
                                    <GameTile :game="g" class="game-tile" />
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

        <div class="media-section" :class="{standalone: !packDetails}">
            <div class="clamped">
                <h3 v-if="game?.promotional?.data" v-t="'GAME_DETAIL.TRAILERS'" class="section-heading title-6" />
                <section class="media">
                    <Carousel
                        :media="game?.promotional?.data" :first-img-trailer="game?.trailerVideoId"
                        v-on:item-clicked="carouselClick"
                    />
                </section>
            </div>
        </div>

        <GameModes
            v-if="game?.features.gameModes.length"
            :game-modes="game.features.gameModes" :window-width="windowWidth"
        />

        <div class="about-section">
            <div class="clamped">
                <h4 v-t="'GAME_DETAIL.ABOUT'" class="section-heading title-6" />
                <section class="about">
                    <div
                        class="padded about-container"
                        :class="{ 'flex-row': windowWidth > 959, 'flex-column': windowWidth < 960 }"
                    >
                        <div class="long-description">
                            <div v-md="game?.longDescription" />
                        </div>
                        <div class="attributes">
                            <div class="details flex-column">
                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.RELEASE_DATE') }}</p>
                                    <p>{{ formattedDate }}</p>
                                </div>

                                <div v-if="gameType" class="detail">
                                    <p>{{ $t('GAME_DETAIL.GAME_TYPE') }}</p>
                                    {{ gameType.name }}
                                </div>

                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.PLAYER_COUNT_HEADER') }}</p>
                                    <p>
                                        {{
                                            $t('GAME_DETAIL.PLAYER_COUNT', {
                                                playerMin: game?.features.counts.playerMin,
                                                playerMax: game?.features.counts.playerMax
                                            })
                                        }}
                                    </p>
                                </div>

                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.DURATION_HEADER') }}</p>
                                    <p>{{ $t('GAME_DETAIL.DURATION', {durationAvg: game?.features.durationAvg}) }}</p>
                                </div>

                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.FAMILY_FRIENDLY') }}</p>
                                    <div v-if="game?.features.options.hasFamilyFriendlyMode">
                                        <i class="fa-solid fa-circle-check" />
                                    </div>
                                    <div v-else>
                                        <i class="fa-solid fa-circle-xmark" />
                                    </div>
                                </div>

                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.MODERATION') }}</p>
                                    <div v-if="game?.features.options.hasModeration">
                                        <i class="fa-solid fa-circle-check" />
                                    </div>
                                    <div v-else>
                                        <i class="fa-solid fa-circle-xmark" />
                                    </div>
                                </div>

                                <div class="detail">
                                    <p>{{ $t('GAME_DETAIL.AUDIENCE') }}</p>
                                    <div v-if="game?.features.counts.audienceMax">
                                        {{ game?.features.counts.audienceMax.toLocaleString(undefined) }}
                                    </div>
                                    <div v-else>
                                        {{ $t('GAME_DETAIL.NOT_APPLICABLE') }}
                                    </div>
                                </div>

                                <div v-if="languages" class="detail">
                                    <p>{{ $t('GAME_DETAIL.LANGUAGES_HEADER') }}</p>
                                    <div class="languages">
                                        <template v-for="(language, i) in languages" :key="i">
                                            <span>{{ language }}<template
                                                v-if="i + 1 < languages.length"
                                            >,&nbsp;</template></span>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    </div>
</div>
</template>

<script setup lang="ts">
import type { Component, CSSProperties } from 'vue'
import type { Shopify } from '@jackboxgames/shopify'
import { I18n } from '$services/i18n'
import Tag from '$components/Tag.vue'
import GameTile from '$components/GameTile.vue'
import Carousel from '$components/carousel/Carousel.vue'
import CarouselModal from '$components/carousel/CarouselModal.vue'
import PackShoppingOverlay from '$components/shopping/PackShoppingOverlay.vue'
import RatingsIcon from '$components/ratings/RatingsIcon.vue'
import GameModes from '$components/GameModes.vue'
import AgeGate, { type BirthdayStatus } from '$components/ratings/AgeGate.vue'
import type { JBGWeb } from '$types/JBGWeb'
import { currentCountry } from '$services/shopify'
import currencyCodeCloudfrontRegionMap from '$components/shopping/currencyCodeCloudfrontRegionMap.json'

const app = useNuxtApp()
const i18n = useI18n()
const route = useRoute()
const localeRoute = useLocaleRoute()
const windowWidth = ref(800)
const gameSlug = route.params.gameslug as string
const packSlug = route.params.packslug as string
const ageStatus: Ref<BirthdayStatus> = ref('unknown')
const fellBackToEn = ref(false)
const product: Ref<Shopify.Product> = ref()

const populate = [
    'packDetails.wordmark',
    'packDetails.gameDetails.title',
    'packDetails.gameDetails.tile',
    'features',
    'features.counts',
    'features.languages',
    'features.types',
    'features.options',
    'features.gameModes',
    'features.gameModes.image',
    'features.gameModes.icon',
    'hero',
    'croppedHero',
    'wordmark',
    'promotional',
    'seo',
    'shopifyProduct',
    'trailerVideoId',
    'tile'
]

const { data: game } = await useAsyncData<JBGWeb.GameDetail>(
    `gamedetail-${packSlug}-${gameSlug}-${i18n.locale.value}`,
    async () => {
        try {
            if (!gameSlug) {
                throw new Error('Game slug unavailable!')
            }
            const game = await app.$cms.fetchOne<JBGWeb.GameDetail>('game-details', gameSlug, {
                populate,
                locale: i18n.locale.value,
                countryCode: currentCountry.code
            })
            return game
        } catch (error) {
            if (error.message === 'No data') {
                // force a fetch in en
                try {
                    const game = await app.$cms.fetchOne<JBGWeb.GameDetail>('game-details', gameSlug, {
                        populate,
                        locale: 'en',
                        countryCode: currentCountry.code
                    })
                    fellBackToEn.value = true
                    return game
                } catch (e) {
                    console.error('error loading game-details', error)
                    throw error
                }
            }
            console.error('error loading game-details', error)
            throw error
        }
    }
)

product.value = game.value.shopifyProduct

let title = ''
if (game.value?.title) {
    title += `${game.value.title}`
}
if (i18n.locale.value !== 'en') {
    title += ` (${i18n.t(I18n.getLocaleNameTag(i18n.locale.value as I18n.Locale))})`
}

const shouldReload = computed(() => {
    let res = false
    // check what currency is present from the fetched data and compare to geolocation cookie
    if (product.value.priceRange.maxVariantPrice.currencyCode) {
        const cc = product.value.priceRange.maxVariantPrice.currencyCode
        const doesMatch = currencyCodeCloudfrontRegionMap[cc].includes(currentCountry.code)
        if (!doesMatch) res = true
    }
    return res
})

watch(shouldReload, async () => {
    if (shouldReload.value === true) {
        await refreshNuxtData(`gamedetail-${packSlug}-${gameSlug}-${i18n.locale.value}`)
        product.value = game.value.shopifyProduct
    }
}, { immediate: true })

// create an array of accessiblity features
const accessibilityFeatures = []
if (game.value.features.options.hasExtendedTimers) {
    accessibilityFeatures.push('hasExtendedTimers')
}
if (game.value.features.options.hasMotionSensitivity) {
    accessibilityFeatures.push('hasMotionSensitivity')
}

// platforms get extracted from the storefront_pricing metaobject
const platforms = game.value.shopifyProduct?.metafields
    ?.find((field) => field.key === 'storefront_pricing')
    ?.reference?.fields
    ?.map((field) => field.key)

const baseUrl = i18n.locale.value === 'en' ? 'https://jackboxgames.com' : `https://jackboxgames.com/${i18n.locale.value}`
const packUrl = game.value.packDetails?.data ? `${baseUrl}/games/packs/${game.value.packDetails.data.attributes.slug}` : undefined
const gameUrl = `${baseUrl}/games/${game.value.slug}`

// the shopify variantinfo has all our pricing info
const variantInfo = game.value.shopifyProduct?.variants[0]

useJsonld(() => ({
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    '@id': gameUrl,
    'abstract': game.value.shortDescription,
    'accessibilityFeatures': accessibilityFeatures,
    'applicationCategory': 'GameApplication',
    'availableOnDevice': platforms,
    'contentRating': game.value.matureRating ? 'Mature' : 'Teen',
    'description': game.value.shortDescription,
    'gamePlatform': platforms,
    'isPartOf': packUrl,
    'name': title,
    'offers': {
        '@context': 'https://schema.org',
        '@type': 'offer',
        '@id': packUrl || gameUrl,
        'alternateName': game.value.packDetails?.data?.attributes.title,
        'price': variantInfo?.price?.amount,
        'priceCurrency': variantInfo?.price?.currencyCode,
        'url': packUrl || gameUrl
    }
}))
useHead({
    title,
    meta: [
        {
            name: 'description',
            content: game.value?.seo?.metaDescription ?? game.value?.shortDescription
        },
        {
            property: 'og:title',
            content: title
        },
        {
            property: 'og:description',
            content: game.value?.shortDescription
        },
        {
            property: 'og:image',
            content: game.value?.tile?.data?.attributes?.url
        }
    ],
    link: [
        {
            rel: 'canonical',
            href: `https://www.jackboxgames.com/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'x-default',
            href: `https://www.jackboxgames.com/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'en',
            href: `https://www.jackboxgames.com/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'fr',
            href: `https://www.jackboxgames.com/fr/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'it',
            href: `https://www.jackboxgames.com/it/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'de',
            href: `https://www.jackboxgames.com/de/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'es-ES',
            href: `https://www.jackboxgames.com/es/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'es-MX',
            href: `https://www.jackboxgames.com/es-XL/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'es',
            href: `https://www.jackboxgames.com/es/games/${gameSlug}`
        },
        {
            rel: 'alternate',
            hreflang: 'pt-BR',
            href: `https://www.jackboxgames.com/pt-BR/games/${gameSlug}`
        }
    ]
})

const classes = computed(() => {
    const classes = []
    if (game.value?.slug === 'the-jackbox-survey-scramble') {
        classes.push('theme-jbss')
    }
    return classes
})

const heroStyles = computed((): CSSProperties => ({
    '--hero-background': `url(${game.value?.hero?.data?.attributes.url}?format=auto)`,
    '--cropped-hero-background': `url(${game.value?.croppedHero?.data?.attributes.url}?format=auto)`
}))

// NOTE: standalones should always be treated as isLocalized false
// because of how GameDetail content is configured in Strapi.
// the slug field is NOT localized, unlike packs so no slicing of suffixes is required,
// which is what this boolean informs in the storefronts service.
const isLocalized = computed(() => false)

const packDetails = computed((): JBGWeb.PackDetail | null => {
    if (!game.value) return null
    return game.value.packDetails?.data?.attributes
})

const isStandalone = computed((): boolean => {
    if (!packDetails.value) return true
    return false
})

const languages = computed(() => {
    const languages: string[] = []
    if (!game.value?.features || !game.value?.features.languages) return []
    if (game.value?.features.languages.length === 0) return []

    game.value?.features.languages.forEach((lang) => {
        languages.push(`${i18n.t(`GAME_DETAIL.LANGUAGES.${lang.language}`)}`)
    })

    return languages
})

const otherGamesInPack = computed((): JBGWeb.GameDetail[] | null => {
    if (!game.value) return null
    if (!packDetails.value) return null

    const gameDetails: JBGWeb.GameDetail[] = packDetails?.value.gameDetails?.data.map((d) => d.attributes)
    const titles = [] as string[]
    gameDetails?.forEach((g) => {
        // eslint wasn't able to infer this was a string without explictly defining it like this... no idea why.
        const title: string = g.title
        titles.push(title)
    })
    const i = titles.indexOf(game.value.title)

    return [...gameDetails.slice(0, i), ...gameDetails.slice(i + 1)]
})

const playerRange = computed(() => {
    if (!game.value?.features || !game.value?.features.counts) return null
    return `${game.value?.features.counts.playerMin} - ${game.value?.features.counts.playerMax}`
})

const duration = computed(() => {
    if (!game.value?.features || !game.value?.features.durationAvg) return null
    return game.value?.features.durationAvg
})

const gameType = computed((): JBGWeb.GameType | null => {
    if (!game.value?.features || !game.value?.features.types) return null
    return game.value?.features?.types?.data[0]?.attributes || null
})

const formattedDate = computed(() => {
    if (!game.value) return null
    const options = {
        year: 'numeric'
    } as const

    const dateObj = new Date(game.value.releaseDate)
    return new Intl.DateTimeFormat(undefined, options).format(dateObj)
})

const typeLink = computed(() => localeRoute({
    name: 'game-type-gametypeslug',
    params: {
        gametypeslug: gameType.value?.slug
    }
}))

const onResize = () => {
    windowWidth.value = window.innerWidth
}

onMounted(() => {
    onResize()
    if (import.meta.client) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        window.addEventListener('resize', onResize)
    }
})

onBeforeUnmount(() => {
    if (import.meta.client) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        window.removeEventListener('resize', onResize)
    }
})

const carouselClick = (itemIndex: number) => {
    void app.$modal.showModal(
        CarouselModal as Component,
        {
            media: game.value?.promotional?.data,
            videoId: game.value?.trailerVideoId,
            itemIndex
        }
    )
}

const setImageOrientationClass = (e: Event) => {
    const image = e.target as HTMLImageElement
    if (!image) return
    if (image.height >= image.width) {
        image.classList.remove('landscape')
        image.classList.add('portrait')
    } else if (image.height < image.width) {
        image.classList.remove('portrait')
        image.classList.add('landscape')
    }
}

const updateStatus = (value: BirthdayStatus) => {
    ageStatus.value = value
}
</script>

<style lang="scss" scoped>
@use "$styles/kit.scss" as *;

.age-gate {
    height: calc(100vh - 430px);
    @include mq-medium {
        height: calc(100vh - 423px);
    }
    @include mq-small-and-less {
        height: calc(100vh - 380px);
    }
}

// backgrounds
.game-details {
    .about-section {
        background: linear-gradient(180deg, #1A2531 0%, #161820 100%);
        // footer fix
        padding-bottom: 320px;
        margin-bottom: -266px;

        @include mq-small-and-less {
            padding-bottom: 280px;
            margin-bottom: -255px;
        }

        .about {
            background: none;
        }
    }

    .hero-section {
        display: flex;
        justify-content: center;
        width: 100%;

        .xs-spacer {
            display: none;
            @include mq-xsmall {
                display: block;
                height: 80px;
            }
        }
    }

    .media-section {
        background: linear-gradient(180deg, #222A3F 0%, #192430 100%);

        .media {
            background: none;
        }
    }

    .pack-section {
        background: linear-gradient(180deg, #111B26 0%, #222A3F 44.29%);
        display: flex;
        flex-direction: row;
        @include mq-small-and-less {
            flex-direction: column;
        }
    }
}


.about {
    .about-container {
        .long-description{
            margin-bottom: 40px;
            min-width: 50%;

            @include mq-small-and-less {
                width: 100%;
            }
        }

        .details {
            background: var(--surface-200);
            border-radius: 8px;
            justify-content: space-between;
            padding: 16px;

            .detail:not(:last-child) {
                border-bottom: 1px solid var(--neutral-500);
            }

            .detail {
                align-items: center;
                display: inline-flex;
                justify-content: space-between;

                .languages{
                    text-align: right;
                    margin-left: 3em;
                    padding: 10px 0;

                    span {
                    display: inline-block;
                    }
                }

                .fa-circle-check, .fa-circle-xmark {
                    color: var(--primary-200)
                }

                p {
                    padding-top: 12px;
                }
            }
        }

        &.flex-row {
            .description{
                max-width: 50%;
                margin-right: 40px;
            }

            .attributes {
                min-width: 50%;
                margin-left: 20px;
            }
        }
    }
}

.description {
    line-height: 25px;
    max-width: 450px;
}

.game-details {
    color: var(--neutral-100);

    .clamped{
        width: 90%
    }

    .ratings-icon {
        width: 87.108px;
        height: auto;
        top: 162px;
        right: 80px;

        @include mq-small-and-less {
            width: 60.69px;
        }

        @include mq-small {
            top: 154px;
            right: 32px;
        }

        @include mq-xsmall {
            top: 58px;
            right: 16px;
        }
    }

    .hero-info {
        @include mq-small-and-more {
            margin: 80px 0px 0px 40px;
        }

        &.mobile {
            background-color: #111b26;
            margin-bottom: 12px;
            position: relative;
            bottom: 0;
            .description {
                max-width: 100%;
                margin-block-end: 0;
            }
        }

        .wordmark {
            &.landscape {
                max-width: 50%;
            }
        }
    }

    .hero {
        position: relative;
        width: 100%;
        margin-top: -138px;
        background-position: center right;
        background-repeat: no-repeat;
        background-size: cover;
        background-image:
            linear-gradient(180deg, rgba(26, 31, 38, 0.00) 95%, #161921 100%),
            radial-gradient(199.81% 112.67% at 92.6% 64.19%,
                rgba(26, 31, 38, 0.00) 15.98%,
                rgba(26, 31, 38, 0.69) 60.91%,
                #1A1F26 100%),
            var(--hero-background);

        @include mq-large-and-more {
            min-height: 960px !important;
            max-width: 1920px;
            background-image:
            linear-gradient(180deg, rgba(26, 31, 38, 0.00) 95%, #161921 100%),
            radial-gradient(48.81% 114.67% at 49.6% 55.19%,
                rgba(26, 31, 38, 0) 80.98%,
                rgba(26, 31, 38, 0.69) 90.91%,
                rgb(26, 31, 38) 100%),
            var(--hero-background);
        }

        @include mq-large {
            min-height: 800px;
            background-image:
                linear-gradient(180deg, rgba(26, 31, 38, 0.00) 95%, #161921 100%),
                radial-gradient(49.81% 142.67% at 49.6% 57.19%,
                    rgba(26, 31, 38, 0) 13.98%,
                    rgba(26, 31, 38, 0.69) 96.91%,
                    rgb(26, 31, 38) 101%),
                var(--hero-background);
        }

        @include mq-medium {
            min-height: 750px;
        }

        @include mq-small {
            min-height: 650px;
        }

        @include mq-xsmall {
            margin: -56px 0 0 0;
            min-height: 600px;
            width: 100%;
            background-position: center;
            background-image:
                radial-gradient(678.04% 45.29% at 50% 100%,
                    #121C28 7.03%,
                    rgba(18, 28, 41, 0.50) 35.3%,
                    rgba(0, 0, 0, 0.00) 100%),
                var(--cropped-hero-background);
        }
    }

    .mobile-sign-in-btn {
        margin-top: 20px;
    }

    .pack-info {
        .flex-layout{
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            @include mq-small-and-less {
                flex-direction: column
            }
        }
    }
}

.game-tiles-container {
    display: flex;
    flex-wrap: wrap;
    margin: -16px -12px;
    padding-bottom: 20px;

    @include mq-small-and-less {
        justify-content: center;
    }

    .game-tile {
        width: 292px;
        margin: 8px 10px;

        @include mq-large-and-more {
            width: 350px;
        }

        @include mq-large {
            width: 270px;
        }

        @include mq-medium {
            width: 200px;
        }

        @include mq-small-and-less {
            width: 300px;
        }
    }
}

.other-titles-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;

    @include mq-small-and-less {
        width: 100%;
        align-items: center;
    }
}

.other-titles {
    font-weight: bold;
    text-align: left;

    @include mq-small-and-less {
        font-size: 13px;
        margin-top: 30px;
    }
}

.shopping {
    margin-right: 42px;

    @include mq-small-and-less {
        margin-right: 0px;
        width: 100%;
    }

    @include mq-xsmall {
        &.standalone {
            position: relative;
            bottom: -186px;
        }
    }

    .overlay-container {
        @include mq-small-and-less {
            display: flex;
            justify-content: center;
        }

        // remove overlay background styling on game detail pages
        :deep(.shopping) {
            background: none;
            backdrop-filter: none;
            box-shadow: none;
            min-width: 250px;
        }
    }
}

.tag-container {
    flex-wrap: wrap;
    padding-bottom: 12px;
}

.release-date {
    font-family: Inter;
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 14px; /* 100% */
    letter-spacing: 2px;
    text-transform: uppercase;
    text-align: center;
}

.theme-jbss.game-details {
    .fold-wrapper {
        background-image: url('/images/survey/jbss-background.jpg');
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }

    .about-section {
        background: transparent;
    }

    .media-section {
        background: transparent;
    }

    .hero {
        background-image: var(--hero-background);

        @include mq-large-and-more {
            background-image:
                linear-gradient(180deg, rgba(26, 31, 38, 0.00) 95%, #161921 100%),
                radial-gradient(48.81% 114.67% at 49.6% 55.19%,
                    rgba(26, 31, 38, 0) 80.98%,
                    rgba(26, 31, 38, 0.69) 90.91%,
                    rgb(26, 31, 38) 100%),
                var(--hero-background);
        }

        @include mq-xsmall {
            background-image: var(--cropped-hero-background);
        }
    }

    @include mq-xsmall {
        .shopping.standalone {
            bottom: -106px;
        }
    }
}
</style>
